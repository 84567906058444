/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getAssetUrl as e } from "../assets.js";
import has from "../core/has.js";
let t,
  o = null;
function r() {
  return !!o;
}
function n() {
  return !!has("esri-wasm");
}
function _() {
  return t || (t = import("./pe-wasm.js").then(e => e.p).then(({
    default: t
  }) => t({
    locateFile: t => e(`esri/geometry/support/${t}`)
  })).then(e => {
    d(e);
  }), t);
}
var P, s;
!function (e) {
  function t(e, t, r) {
    o.ensureCache.prepare();
    const n = D(r),
      _ = r === n,
      P = o.ensureFloat64(n),
      s = o._pe_geog_to_proj(o.getPointer(e), t, P);
    return s && R(r, t, P, _), s;
  }
  function r(e, o, r, _) {
    switch (_) {
      case s.PE_TRANSFORM_P_TO_G:
        return n(e, o, r);
      case s.PE_TRANSFORM_G_TO_P:
        return t(e, o, r);
    }
    return 0;
  }
  function n(e, t, o) {
    return _(e, t, o, 0);
  }
  function _(e, t, r, n) {
    o.ensureCache.prepare();
    const _ = D(r),
      P = r === _,
      s = o.ensureFloat64(_),
      i = o._pe_proj_to_geog_center(o.getPointer(e), t, s, n);
    return i && R(r, t, s, P), i;
  }
  e.geogToProj = t, e.projGeog = r, e.projToGeog = n, e.projToGeogCenter = _;
}(P || (P = {})), function (e) {
  function t() {
    e.PE_BUFFER_MAX = o.PeDefs.prototype.PE_BUFFER_MAX, e.PE_NAME_MAX = o.PeDefs.prototype.PE_NAME_MAX, e.PE_MGRS_MAX = o.PeDefs.prototype.PE_MGRS_MAX, e.PE_USNG_MAX = o.PeDefs.prototype.PE_USNG_MAX, e.PE_DD_MAX = o.PeDefs.prototype.PE_DD_MAX, e.PE_DDM_MAX = o.PeDefs.prototype.PE_DDM_MAX, e.PE_DMS_MAX = o.PeDefs.prototype.PE_DMS_MAX, e.PE_UTM_MAX = o.PeDefs.prototype.PE_UTM_MAX, e.PE_PARM_MAX = o.PeDefs.prototype.PE_PARM_MAX, e.PE_TYPE_NONE = o.PeDefs.prototype.PE_TYPE_NONE, e.PE_TYPE_GEOGCS = o.PeDefs.prototype.PE_TYPE_GEOGCS, e.PE_TYPE_PROJCS = o.PeDefs.prototype.PE_TYPE_PROJCS, e.PE_TYPE_GEOGTRAN = o.PeDefs.prototype.PE_TYPE_GEOGTRAN, e.PE_TYPE_COORDSYS = o.PeDefs.prototype.PE_TYPE_COORDSYS, e.PE_TYPE_UNIT = o.PeDefs.prototype.PE_TYPE_UNIT, e.PE_TYPE_LINUNIT = o.PeDefs.prototype.PE_TYPE_LINUNIT, e.PE_STR_OPTS_NONE = o.PeDefs.prototype.PE_STR_OPTS_NONE, e.PE_STR_AUTH_NONE = o.PeDefs.prototype.PE_STR_AUTH_NONE, e.PE_STR_AUTH_TOP = o.PeDefs.prototype.PE_STR_AUTH_TOP, e.PE_STR_NAME_CANON = o.PeDefs.prototype.PE_STR_NAME_CANON, e.PE_STR_FMT_WKT = o.PeDefs.prototype.PE_STR_FMT_WKT, e.PE_STR_FMT_WKT2 = o.PeDefs.prototype.PE_STR_FMT_WKT2, e.PE_PARM_X0 = o.PeDefs.prototype.PE_PARM_X0, e.PE_PARM_ND = o.PeDefs.prototype.PE_PARM_ND, e.PE_TRANSFORM_1_TO_2 = o.PeDefs.prototype.PE_TRANSFORM_1_TO_2, e.PE_TRANSFORM_2_TO_1 = o.PeDefs.prototype.PE_TRANSFORM_2_TO_1, e.PE_TRANSFORM_P_TO_G = o.PeDefs.prototype.PE_TRANSFORM_P_TO_G, e.PE_TRANSFORM_G_TO_P = o.PeDefs.prototype.PE_TRANSFORM_G_TO_P, e.PE_HORIZON_RECT = o.PeDefs.prototype.PE_HORIZON_RECT, e.PE_HORIZON_POLY = o.PeDefs.prototype.PE_HORIZON_POLY, e.PE_HORIZON_LINE = o.PeDefs.prototype.PE_HORIZON_LINE, e.PE_HORIZON_DELTA = o.PeDefs.prototype.PE_HORIZON_DELTA;
  }
  e.init = t;
}(s || (s = {}));
let i = null;
var p;
!function (e) {
  const t = {},
    r = {},
    n = e => {
      const t = e.getType();
      switch (t) {
        case s.PE_TYPE_GEOGCS:
          e = o.castObject(e, o.PeGeogcs);
          break;
        case s.PE_TYPE_PROJCS:
          e = o.castObject(e, o.PeProjcs);
          break;
        case s.PE_TYPE_GEOGTRAN:
          e = o.castObject(e, o.PeGeogtran);
          break;
        default:
          t & s.PE_TYPE_UNIT && (e = o.castObject(e, o.PeUnit));
      }
      return e;
    };
  function _() {
    o.PeFactory.prototype.initialize(null);
  }
  function P(e) {
    return i(s.PE_TYPE_COORDSYS, e);
  }
  function i(e, r) {
    let _ = null,
      P = t[e];
    if (P || (P = {}, t[e] = P), P.hasOwnProperty(String(r)) && (_ = P[r], o.compare(_, o.NULL) && (_ = null)), !_) {
      const t = o.PeFactory.prototype.factoryByType(e, r);
      o.compare(t, o.NULL) || (_ = n(t), P[r] = _);
    }
    return _;
  }
  function p(e, t) {
    let _ = null,
      P = r[e];
    if (P || (P = {}, r[e] = P), P.hasOwnProperty(t) && (_ = P[t], o.compare(_, o.NULL) && (_ = null)), !_) {
      const r = o.PeFactory.prototype.fromString(e, t);
      o.compare(r, o.NULL) || (_ = n(r), P[t] = _);
    }
    return _;
  }
  function u(e) {
    return i(s.PE_TYPE_GEOGCS, e);
  }
  function E(e) {
    return i(s.PE_TYPE_GEOGTRAN, e);
  }
  function c(e) {
    return o.PeFactory.prototype.getCode(e);
  }
  function a(e) {
    return i(s.PE_TYPE_PROJCS, e);
  }
  function g(e) {
    return i(s.PE_TYPE_UNIT, e);
  }
  e.initialize = _, e.coordsys = P, e.factoryByType = i, e.fromString = p, e.geogcs = u, e.geogtran = E, e.getCode = c, e.projcs = a, e.unit = g;
}(p || (p = {}));
let u = null;
var E, c, a, g, T, f, l, y, S, O, N;
function d(e) {
  function t(e, t, o) {
    e[t] = o(e[t]);
  }
  o = e, s.init(), E.init(), l.init(), S.init(), O.init(), i = class extends o.PeDouble {
    constructor(e = NaN) {
      super(e);
    }
    destroy() {
      o.destroy(this);
    }
    [Symbol.dispose]() {
      this.destroy();
    }
  }, u = class extends o.PeGCSExtent {
    destroy() {
      o.destroy(this);
    }
    [Symbol.dispose]() {
      this.destroy();
    }
  };
  const r = [o.PeAngunit, o.PeDatum, o.PeGeogcs, o.PeGeogtran, o.PeObject, o.PeParameter, o.PePrimem, o.PeProjcs, o.PeSpheroid, o.PeUnit];
  for (const o of r) t(o.prototype, "getName", e => function () {
    return e.call(this, new Array(s.PE_NAME_MAX));
  });
  for (const P of [o.PeGeogtran, o.PeProjcs]) t(P.prototype, "getParameters", e => function () {
    const t = new Array(s.PE_PARM_MAX);
    let r = e.call(this);
    for (let e = 0; e < t.length; e++) {
      const n = o.getValue(r, "*");
      t[e] = n ? o.wrapPointer(n, o.PeParameter) : null, r += Int32Array.BYTES_PER_ELEMENT;
    }
    return t;
  });
  t(o.PeHorizon.prototype, "getCoord", e => function (t = !1) {
    const o = this.getSize();
    if (!o) return null;
    const r = [];
    return R(r, o, e.call(this), t), r;
  }), t(o.PeGTlistExtendedEntry.prototype, "getEntries", e => {
    const t = o._pe_getPeGTlistExtendedGTsSize();
    return function () {
      let r = null;
      const n = e.call(this);
      if (!o.compare(n, o.NULL)) {
        r = [n];
        const e = this.getSteps();
        if (e > 1) {
          const _ = o.getPointer(n);
          for (let n = 1; n < e; n++) r.push(o.wrapPointer(_ + t * n, o.PeGTlistExtendedGTs));
        }
      }
      return r;
    };
  });
  const n = o._pe_getPeHorizonSize(),
    _ = e => function () {
      let t = this._cache;
      if (t || (t = new Map(), this._cache = t), t.has(e)) return t.get(e);
      let r = null;
      const _ = e.call(this);
      if (!o.compare(_, o.NULL)) {
        r = [_];
        const e = _.getNump();
        if (e > 1) {
          const t = o.getPointer(_);
          for (let _ = 1; _ < e; _++) r.push(o.wrapPointer(t + n * _, o.PeHorizon));
        }
      }
      return t.set(e, r), r;
    };
  t(o.PeProjcs.prototype, "horizonGcsGenerate", _), t(o.PeProjcs.prototype, "horizonPcsGenerate", _), o.PeObject.prototype.toString = function (e = s.PE_STR_OPTS_NONE) {
    o.ensureCache.prepare();
    const t = o.getPointer(this),
      r = o.ensureInt8(new Array(s.PE_BUFFER_MAX));
    return o.UTF8ToString(o._pe_object_to_string_ext(t, e, r));
  }, o.PeGeogcs.prototype.destroy = function () {
    this.Delete(), M(this), this.ptr = 0;
  }, o.PeGeogcs.prototype[Symbol.dispose] = function () {
    this.destroy();
  }, o.PeProjcs.prototype.destroy = function () {
    this.Delete(), M(this), this.ptr = 0;
  }, o.PeProjcs.prototype[Symbol.dispose] = function () {
    this.destroy();
  };
}
function M(e) {
  if (!e) return;
  const t = o.getClass(e);
  if (!t) return;
  const r = o.getCache(t);
  if (!r) return;
  const n = o.getPointer(e);
  n && delete r[n];
}
function A(e, t) {
  const r = [],
    n = new Array(t);
  for (let _ = 0; _ < e; _++) r.push(o.ensureInt8(n));
  return r;
}
function D(e) {
  return ArrayBuffer.isView(e) ? e : Array.isArray(e[0]) ? e.flat() : e;
}
function R(e, t, r, n = !1) {
  if (n) for (let _ = 0; _ < 2 * t; _++) e[_] = o.getValue(r + _ * Float64Array.BYTES_PER_ELEMENT, "double");else {
    const n = 0 === e.length;
    for (let _ = 0; _ < t; _++) n && (e[_] = new Array(2)), e[_][0] = o.getValue(r, "double"), e[_][1] = o.getValue(r + Float64Array.BYTES_PER_ELEMENT, "double"), r += 2 * Float64Array.BYTES_PER_ELEMENT;
  }
}
!function (e) {
  let t;
  function r() {
    e.PE_GTLIST_OPTS_COMMON = o.PeGTlistExtended.prototype.PE_GTLIST_OPTS_COMMON, t = o._pe_getPeGTlistExtendedEntrySize();
  }
  function n(e, r, n, _, P, s) {
    let i = null;
    const p = new o.PeInteger(s);
    try {
      const u = o.PeGTlistExtended.prototype.getGTlist(e, r, n, _, P, p);
      if ((s = p.val) && (i = [u], s > 1)) {
        const e = o.getPointer(u);
        for (let r = 1; r < s; r++) i.push(o.wrapPointer(e + t * r, o.PeGTlistExtendedEntry));
      }
    } finally {
      o.destroy(p);
    }
    return i;
  }
  e.init = r, e.getGTlist = n;
}(E || (E = {})), function (e) {
  function t(e) {
    if (e?.length) {
      for (const t of e) M(t), t.getEntries().forEach(e => {
        M(e);
        const t = e.getGeogtran();
        M(t), t.getParameters().forEach(M), [t.getGeogcs1(), t.getGeogcs2()].forEach(e => {
          M(e);
          const t = e.getDatum();
          M(t), M(t.getSpheroid()), M(e.getPrimem()), M(e.getUnit());
        });
      });
      o.PeGTlistExtendedEntry.prototype.Delete(e[0]);
    }
  }
  e.destroy = t;
}(c || (c = {})), function (e) {
  function t(e, t, r, n, _) {
    o.ensureCache.prepare();
    const P = D(r),
      s = r === P,
      i = o.ensureFloat64(P);
    let p = 0;
    n && (p = o.ensureFloat64(n));
    const u = o._pe_geog_to_geog(o.getPointer(e), t, i, p, _);
    return u && R(r, t, i, s), u;
  }
  e.geogToGeog = t;
}(a || (a = {})), function (e) {
  function t(e, t, r, n, _, P, s, i) {
    o.PeLineType.prototype.geodesic_coordinate(e, t, r, n, _, P, s, i);
  }
  function r(e, t, r, n, _, P, s, i, p) {
    o.PeLineType.prototype.geodetic_coordinate(e, t, r, n, _, P, s, i, p);
  }
  function n(e, t, r, n, _, P, s, i, p, u) {
    o.PeLineType.prototype.geodetic_distance(e, t, r, n, _, P, s, i, p, u);
  }
  function _(e, t, r, n, _, P, s, i, p) {
    o.PeLineType.prototype.great_elliptic_distance(e, t, r, n, _, P, s, i, p);
  }
  e.geodesicCoordinate = t, e.geodeticCoordinate = r, e.geodeticDistance = n, e.greatEllipticDistance = _;
}(g || (g = {})), function (e) {
  function t(e, t) {
    return o.PeMath.prototype.phi_to_eta(e, t);
  }
  function r(e, t) {
    return o.PeMath.prototype.eta_to_phi(e, t);
  }
  function n(e, t) {
    return o.PeMath.prototype.phi_to_phig(e, t);
  }
  function _(e, t, r) {
    return o.PeMath.prototype.q(e, t, r);
  }
  function P(e, t) {
    return o.PeMath.prototype.q90(e, t);
  }
  e.phiToEta = t, e.etaToPhi = r, e.phiToPhig = n, e.q = _, e.q90 = P;
}(T || (T = {})), function (e) {
  const t = (e, t, r, n, _, P) => {
      let i, p;
      switch (o.ensureCache.prepare(), e) {
        case "dd":
          i = o._pe_geog_to_dd, p = s.PE_DD_MAX;
          break;
        case "ddm":
          i = o._pe_geog_to_ddm, p = s.PE_DDM_MAX;
          break;
        case "dms":
          i = o._pe_geog_to_dms, p = s.PE_DMS_MAX;
      }
      let u = 0;
      t && (u = o.getPointer(t));
      const E = D(n),
        c = o.ensureFloat64(E),
        a = A(r, p),
        g = i(u, r, c, _, o.ensureInt32(a));
      if (g) for (let s = 0; s < r; s++) P[s] = o.UTF8ToString(a[s]);
      return g;
    },
    r = (e, t, r, n, _) => {
      let P;
      switch (o.ensureCache.prepare(), e) {
        case "dd":
          P = o._pe_dd_to_geog;
          break;
        case "ddm":
          P = o._pe_ddm_to_geog;
          break;
        case "dms":
          P = o._pe_dms_to_geog;
      }
      let s = 0;
      t && (s = o.getPointer(t));
      const i = n.map(e => o.ensureString(e)),
        p = o.ensureInt32(i),
        u = o.ensureFloat64(new Array(2 * r)),
        E = P(s, r, p, u);
      return E && R(_, r, u), E;
    };
  function n(e, o, r, n, _) {
    return t("dms", e, o, r, n, _);
  }
  function _(e, t, o, n) {
    return r("dms", e, t, o, n);
  }
  function P(e, o, r, n, _) {
    return t("ddm", e, o, r, n, _);
  }
  function i(e, t, o, n) {
    return r("ddm", e, t, o, n);
  }
  function p(e, o, r, n, _) {
    return t("dd", e, o, r, n, _);
  }
  function u(e, t, o, n) {
    return r("dd", e, t, o, n);
  }
  e.geogToDms = n, e.dmsToGeog = _, e.geogToDdm = P, e.ddmToGeog = i, e.geogToDd = p, e.ddToGeog = u;
}(f || (f = {})), function (e) {
  function t() {
    e.PE_MGRS_STYLE_NEW = o.PeNotationMgrs.prototype.PE_MGRS_STYLE_NEW, e.PE_MGRS_STYLE_OLD = o.PeNotationMgrs.prototype.PE_MGRS_STYLE_OLD, e.PE_MGRS_STYLE_AUTO = o.PeNotationMgrs.prototype.PE_MGRS_STYLE_AUTO, e.PE_MGRS_180_ZONE_1_PLUS = o.PeNotationMgrs.prototype.PE_MGRS_180_ZONE_1_PLUS, e.PE_MGRS_ADD_SPACES = o.PeNotationMgrs.prototype.PE_MGRS_ADD_SPACES;
  }
  function r(e, t, r, n, _, P, i) {
    o.ensureCache.prepare();
    let p = 0;
    e && (p = o.getPointer(e));
    const u = D(r),
      E = o.ensureFloat64(u),
      c = A(t, s.PE_MGRS_MAX),
      a = o.ensureInt32(c),
      g = o._pe_geog_to_mgrs_extended(p, t, E, n, _, P, a);
    if (g) for (let s = 0; s < t; s++) i[s] = o.UTF8ToString(c[s]);
    return g;
  }
  function n(e, t, r, n, _) {
    o.ensureCache.prepare();
    let P = 0;
    e && (P = o.getPointer(e));
    const s = r.map(e => o.ensureString(e)),
      i = o.ensureInt32(s),
      p = o.ensureFloat64(new Array(2 * t)),
      u = o._pe_mgrs_to_geog_extended(P, t, i, n, p);
    return u && R(_, t, p), u;
  }
  e.init = t, e.geogToMgrsExtended = r, e.mgrsToGeogExtended = n;
}(l || (l = {})), function (e) {
  function t(e, t, r, n, _, P, i) {
    o.ensureCache.prepare();
    let p = 0;
    e && (p = o.getPointer(e));
    const u = D(r),
      E = o.ensureFloat64(u),
      c = A(t, s.PE_MGRS_MAX),
      a = o.ensureInt32(c),
      g = o._pe_geog_to_usng(p, t, E, n, _, P, a);
    if (g) for (let s = 0; s < t; s++) i[s] = o.UTF8ToString(c[s]);
    return g;
  }
  function r(e, t, r, n) {
    o.ensureCache.prepare();
    let _ = 0;
    e && (_ = o.getPointer(e));
    const P = r.map(e => o.ensureString(e)),
      s = o.ensureInt32(P),
      i = o.ensureFloat64(new Array(2 * t)),
      p = o._pe_usng_to_geog(_, t, s, i);
    return p && R(n, t, i), p;
  }
  e.geogToUsng = t, e.usngToGeog = r;
}(y || (y = {})), function (e) {
  function t() {
    e.PE_UTM_OPTS_NONE = o.PeNotationUtm.prototype.PE_UTM_OPTS_NONE, e.PE_UTM_OPTS_ADD_SPACES = o.PeNotationUtm.prototype.PE_UTM_OPTS_ADD_SPACES, e.PE_UTM_OPTS_NS = o.PeNotationUtm.prototype.PE_UTM_OPTS_NS;
  }
  function r(e, t, r, n, _) {
    o.ensureCache.prepare();
    let P = 0;
    e && (P = o.getPointer(e));
    const i = D(r),
      p = o.ensureFloat64(i),
      u = A(t, s.PE_UTM_MAX),
      E = o.ensureInt32(u),
      c = o._pe_geog_to_utm(P, t, p, n, E);
    if (c) for (let s = 0; s < t; s++) _[s] = o.UTF8ToString(u[s]);
    return c;
  }
  function n(e, t, r, n, _) {
    o.ensureCache.prepare();
    let P = 0;
    e && (P = o.getPointer(e));
    const s = r.map(e => o.ensureString(e)),
      i = o.ensureInt32(s),
      p = o.ensureFloat64(new Array(2 * t)),
      u = o._pe_utm_to_geog(P, t, i, n, p);
    return u && R(_, t, p), u;
  }
  e.init = t, e.geogToUtm = r, e.utmToGeog = n;
}(S || (S = {})), function (e) {
  const t = new Map();
  function r() {
    e.PE_PCSINFO_OPTION_NONE = o.PePCSInfo.prototype.PE_PCSINFO_OPTION_NONE, e.PE_PCSINFO_OPTION_DOMAIN = o.PePCSInfo.prototype.PE_PCSINFO_OPTION_DOMAIN, e.PE_POLE_OUTSIDE_BOUNDARY = o.PePCSInfo.prototype.PE_POLE_OUTSIDE_BOUNDARY, e.PE_POLE_POINT = o.PePCSInfo.prototype.PE_POLE_POINT;
  }
  function n(r, n = e.PE_PCSINFO_OPTION_DOMAIN) {
    let _ = null,
      P = null;
    return t.has(r) && (P = t.get(r), P[n] && (_ = P[n])), _ || (_ = o.PePCSInfo.prototype.generate(r, n), P || (P = [], t.set(r, P)), P[n] = _), _;
  }
  e.init = r, e.generate = n;
}(O || (O = {})), function (e) {
  function t() {
    return o.PeVersion.prototype.version_string();
  }
  e.versionString = t;
}(N || (N = {}));
const G = Object.freeze(Object.defineProperty({
  __proto__: null,
  get PeCSTransformations() {
    return P;
  },
  get PeDefs() {
    return s;
  },
  get PeDouble() {
    return i;
  },
  get PeFactory() {
    return p;
  },
  get PeGCSExtent() {
    return u;
  },
  get PeGTTransformations() {
    return a;
  },
  get PeGTlistExtended() {
    return E;
  },
  get PeGTlistExtendedEntry() {
    return c;
  },
  get PeLineType() {
    return g;
  },
  get PeMath() {
    return T;
  },
  get PeNotationDms() {
    return f;
  },
  get PeNotationMgrs() {
    return l;
  },
  get PeNotationUsng() {
    return y;
  },
  get PeNotationUtm() {
    return S;
  },
  get PePCSInfo() {
    return O;
  },
  get PeVersion() {
    return N;
  },
  _init: d,
  get _pe() {
    return o;
  },
  isLoaded: r,
  isSupported: n,
  load: _
}, Symbol.toStringTag, {
  value: "Module"
}));
export { p as P, o as _, s as a, n as b, f as c, l as d, y as e, S as f, P as g, a as h, r as i, O as j, i as k, _ as l, u as m, E as n, c as o, G as p, g as q, T as r, N as s, d as t };