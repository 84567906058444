/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
class i {
  constructor(i, t, e, s, h, n) {
    this.semiMajorAxis = i, this.flattening = t, this.outerAtmosphereRimWidth = e, this.atmosphereHeight = s, this.scaleHeight = h;
    const r = 1 - this.flattening;
    this.semiMinorAxis = this.semiMajorAxis * r, this.halfSemiMajorAxis = this.semiMajorAxis / 2, this.halfCircumference = Math.PI * this.semiMajorAxis, this.metersPerDegree = this.halfCircumference / 180, this.inverseFlattening = 1 / (1 - this.flattening) - 1, this.eccentricitySquared = n || 2 * this.flattening - this.flattening * this.flattening, this.meanRadiusSemiAxes = (2 * this.semiMajorAxis + this.semiMinorAxis) / 3;
  }
  get radius() {
    return this.semiMajorAxis;
  }
}
const t = new i(6378137, 1 / 298.257223563, 3e5, 1e5, .085, .006694379990137799),
  e = new i(3396190, 1 / 169.8944472236118, 23e4, 6e4, .111),
  s = new i(1737400, 0, 0, 0, 0);
export { i as Ellipsoid, t as earth, e as mars, s as moon };